@import url(https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,700;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  font-size: 20px;
  color: black;
  font-family: 'Bitter', Georgia, sans-serif;
  padding: 0;
  background-color: #fff;
  color: #555;
  margin: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}

.title {
  font-size: 44px;
  color:#444;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -3px;
  font-family: 'Helvetica Neue', Helvetica, Arial, serif;
}

.nav {
  margin-left:20px;
}

.content {
  margin-left: 130px;
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.links a {
  text-decoration: none;
  color:#222;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.links a:hover {
  color: #dd5424;
}

.links a img {
  border: 0;
  height: 24px;
  margin-right: 8px;
}
